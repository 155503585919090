import {InjectionToken} from '@angular/core';

import {AZA_CORE_DEFAULT_SNACKBAR_DURATION} from '../../const/default-snack-bar-duraction';

export const AZA_CORE_SNACKBAR_TIME_CLOSE = new InjectionToken<number | null>(
  'AZA_CORE_SNACKBAR_TIME_CLOSE',
  {
    providedIn: 'root',
    factory: () => AZA_CORE_DEFAULT_SNACKBAR_DURATION,
  },
);
