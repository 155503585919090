import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';

import {AccountId} from '@azarus/api-contract';

import {API_GAME_HTTP_URL} from '../../tokens';
import {ApiGamePlatforms} from '../../types';
import {ConnectedStreamerAccount} from '../types/connected-streamer-account';

@Injectable({providedIn: 'root'})
export class ApiGameStreamerAccountsService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getConnectedList(): Observable<ConnectedStreamerAccount[]> {
    return this._httpClient
      .get<{
        data: ConnectedStreamerAccount[];
      }>(`${this._httpUrl}/streamer/accounts`)
      .pipe(map(({data}) => data));
  }

  public disconnect(
    platform: ApiGamePlatforms,
    accountId: AccountId,
  ): Observable<unknown> {
    return this._httpClient.delete(
      `${this._httpUrl}/streamer/accounts/${platform}/${accountId}`,
    );
  }
}
