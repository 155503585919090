import {Observable, Subject} from 'rxjs';

export class AzaCoreSnackbarRef<D> {
  private readonly _afterClosed = new Subject<D | undefined>();
  public constructor(private readonly _closeFn: () => void) {}
  public close(result?: D): void {
    this._closeFn();
    this._afterClosed.next(result);
    this._afterClosed.complete();
  }
  public afterClosed(): Observable<D | undefined> {
    return this._afterClosed.asObservable();
  }
}
