import {UserId} from '@azarus/api-contract';
import {IsoDateString} from '@azarus/common/type/iso-date-string';
import {ContractAddress} from '@azarus/frontend/api/game';

export interface BlockchainBalanceUpdatedMessage {
  version: number;
  type: 'event';
  name: 'blockchain.balance.updated';
  time: IsoDateString;
  payload: {
    data: {
      identityId: string;
      userId: UserId;
      publicKey: string;
    };
    metadata: {
      network: string;
      tokenContract: ContractAddress;
    };
    serverTime: IsoDateString;
  };
}

export function isBlockchainBalanceUpdatedMessage(
  m: unknown,
): m is BlockchainBalanceUpdatedMessage {
  return (
    typeof m === 'object' &&
    m !== null &&
    'name' in m &&
    m.name === 'blockchain.balance.updated'
  );
}
