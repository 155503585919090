import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../../tokens';
import {ApiGameListGame} from '../../../list-games';
import {ApiGameClassicTriviaPartyCreateRequest} from '../types';

@Injectable({providedIn: 'root'})
export class ApiGameClassicTriviaEnrollmentsService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public startParty({
    userId,
    gameId,
    gameVariationId,
  }: Omit<
    ApiGameClassicTriviaPartyCreateRequest,
    'data'
  >): Observable<ApiGameListGame> {
    return this._httpClient.post<ApiGameListGame>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/enrollments`,
      {},
    );
  }
}
