import {inject, InjectionToken} from '@angular/core';
import {catchError, filter, map, of, startWith, switchMap} from 'rxjs';

import {retentionState} from '@azarus/common/rx/retention-state';
import {ApiGameUserBalanceService} from '@azarus/frontend/api/game';

import {UserInfoActionService} from '../services/user-info-action.service';
import {WsService} from '../services/ws.service';
import {isBlockchainBalanceUpdatedMessage} from '../types/blockchain-balance-updated-message';

export const USER_BALANCE = new InjectionToken('USER_BALANCE', {
  providedIn: 'root',
  factory: () => {
    const api = inject(ApiGameUserBalanceService);
    const userInfo = inject(UserInfoActionService);
    const balanceChangeNotifier$ = inject(WsService).wsMessages$.pipe(
      filter(isBlockchainBalanceUpdatedMessage),
    );
    return balanceChangeNotifier$.pipe(
      startWith(null),
      switchMap(() => userInfo.info$),
      switchMap(({_id}) => api.getBalance(_id)),
      map((response) => {
        const constractString = response.defaultTokenContract.toLowerCase();
        const balanceRecord = response.tokenBalances.find(
          (b) => b.contract.toLowerCase() === constractString,
        );
        if (balanceRecord === undefined) {
          // for new users it hapens
          return 0;
        }
        return Number.parseFloat(balanceRecord.displayValue);
      }),
      // FIXME: remove this catch, it is made to fix backend error
      catchError((_e) => of(0)),
      retentionState(2000),
    );
  },
});
