export enum ApiGameGameStep {
  START = 'START',
  QUESTION = 'QUESTION',
  SCORE = 'SCORE',
  QUESTION_LAUNCH = 'QUESTION_LAUNCH',
  ANSWER = 'ANSWER',
  WINNER_SELECTION_START = 'WINNER_SELECTION_START',
  WINNER_SELECTION_END = 'WINNER_SELECTION_END',
  COLLECTING_ANSWERS = 'COLLECTING_ANSWERS',
}
