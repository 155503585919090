import {animate, style, transition, trigger} from '@angular/animations';

export const AZA_CORE_SNACKBAR_ANIMATION = trigger('snackbarAnimation', [
  transition(':enter', [
    style({
      transform: 'scale(0.8)',
      opacity: 0,
    }),
    animate(
      '150ms 75ms cubic-bezier(0, 0, 0.2, 1)',
      style({transform: '*', opacity: '*'}),
    ),
  ]),
]);
