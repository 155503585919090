import {ComponentType} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';

import {AzaCoreSnackbarComponent} from './snackbar.component';
import {AzaCoreSnackbarService} from './snackbar.service';
import {AzaCoreSnackbarOptions} from './snackbar-options';
import {AzaCoreSnackbarRef} from './snackbar-ref';

@Injectable({providedIn: 'root'})
export class AzaCoreCustomComponentSnackbarService extends AzaCoreSnackbarService {
  private _component: ComponentType<unknown> | null = null;

  protected override get snackbarComponent(): ComponentType<AzaCoreSnackbarComponent> {
    if (this._component === null) {
      return super.snackbarComponent;
    }
    return this._component as any;
  }

  public openWithCompnent<D>(
    text: string,
    options?: AzaCoreSnackbarOptions,
    component?: ComponentType<unknown>,
  ): AzaCoreSnackbarRef<D> {
    this._component = component ?? null;
    return this.open(text, options);
  }
}
