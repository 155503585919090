import {inject} from '@angular/core';
import {isScullyRunning} from '@scullyio/ng-lib';

import {AzaCoreCustomComponentSnackbarService} from '@azarus/frontend/core/modules/snackbar/custom-component-snackbar.service';

import {AzaCoreClosableErrorSnackbarComponent} from '../components/closable-error-snackbar.component';
import {AzaCoreClosableSnackbarComponent} from '../components/closable-snackbar.component';

function cleanUrl(): void {
  const url = new URL(window.location.href);
  url.searchParams.delete('state');
  window.history.replaceState({}, '', url.toString());
}

export function twitchConnectResultCatchInitializer(): () => void {
  const snackbar = inject(AzaCoreCustomComponentSnackbarService);
  return () => {
    if (isScullyRunning()) {
      return;
    }
    const url = new URL(window.location.href);
    const paramValue = url.searchParams.get('state');
    if (paramValue === null) {
      return;
    }
    let statePayload: Record<string, any> | null = null;
    try {
      statePayload = JSON.parse(atob(paramValue));
      if (statePayload === null || typeof statePayload !== 'object') {
        return;
      }
      if (
        statePayload.error === null &&
        'connectedTwitchStreamerAccount' in statePayload
      ) {
        cleanUrl();
        snackbar.openWithCompnent(
          'Account has been connected',
          {timeToCloseMs: 10000},
          AzaCoreClosableSnackbarComponent,
        );
        return;
      }
      if (statePayload.error !== null) {
        cleanUrl();
        snackbar.openWithCompnent(
          `Error connecting account: ${statePayload.error.message}`,
          {timeToCloseMs: null},
          AzaCoreClosableErrorSnackbarComponent,
        );
        return;
      }
    } catch (_e) {
      return;
    }
  };
}
