import {
  API_GATEWAY_HTTP_URL_STAGE,
  API_GATEWAY_WS_URL_STAGE,
} from '@azarus/api-contract';

import {WebsiteEnvironment} from './website-environment';

/**
 * paypal.env, paypal.merchantId only for GooglePAy
 */
export const environment: WebsiteEnvironment = {
  production: false,
  authApp: 'wa-corporate',
  storageKeyPrefix: 'azarus_web_',
  authSearchParamJwtKey: 'jwt',
  authSearchParamRedirectUrl: 'redirectUrl',
  rrpcGatewayWsUrl: API_GATEWAY_WS_URL_STAGE,
  apiGatewayHttpUrl: API_GATEWAY_HTTP_URL_STAGE,
  gameLoaderUrl: 'https://game-loader-stage.azarus.io',
  apiGameHttpUrl: 'https://api.staging.azarus.io',
  apiGameWsUrl: 'ws.engine.staging.azarus.io',
  sentryEnabled: false,
  https: true,
  googleTagManagerId: 'GTM-PFNQP96',
  googleTagManagerPreview: 'env-9',
  googleTagManagerAuth: '3EYqxC5XPetCCKuqUFW5KQ',
  challengesSiteUrl: 'https://challenges-stage.azarus.io',
  paypal: {
    env: 'TEST',
    clientId:
      'AZtbvFFyULuVr_w4VXfjeXyUJrtCvB0zo92MVmDGeVZdEabWveHsl44QpC-KFZd1Q5tgWO2WiJzEwYrg',
    merchantId: '9829D63ZW7LUC',
  },
  googleSheetsUrl:
    'https://script.google.com/macros/s/AKfycby_yjea5hZUC5ksBmy6J2u2wzVzTrd_CbZqiDhIHNJ3_BrmWfpunsvM-myVg7Yd1teb6A/exec',
  googleIdentity: {
    clientId:
      '484738907444-u4fi014cac6htddc3k2sbs8f5lsoutor.apps.googleusercontent.com',
  },
  twitchClientId: 'aw1e48e1d9cwshw0et7k7bwben1r8v',
  twitchRedirectUri: 'http://localhost:4200',
};
