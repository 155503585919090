import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../../tokens';
import {ApiGameClassicTriviaPartyQuestionMultiply} from '../types';
import {ApiGameClassicTriviaQuestionAdd} from '../types/question-add';
import {ApiGamaClassicTriviaQuestionPatch} from '../types/question-patch';

@Injectable({providedIn: 'root'})
export class ApiGameClassicTriviaQuestionsService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public addQuestion({
    userId,
    gameId,
    gameVariationId,
    classicTriviaPartyId,
    data,
  }: ApiGameClassicTriviaQuestionAdd): Observable<ApiGameClassicTriviaPartyQuestionMultiply> {
    return this._httpClient.post<ApiGameClassicTriviaPartyQuestionMultiply>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${classicTriviaPartyId}/questions`,
      data,
    );
  }

  public patchQuestion({
    userId,
    gameId,
    gameVariationId,
    classicTriviaPartyId,
    questionId,
    data,
  }: ApiGamaClassicTriviaQuestionPatch): Observable<ApiGameClassicTriviaPartyQuestionMultiply> {
    return this._httpClient.patch<ApiGameClassicTriviaPartyQuestionMultiply>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${classicTriviaPartyId}/questions/${questionId}`,
      data,
    );
  }

  public deleteQuestion({
    userId,
    gameId,
    gameVariationId,
    classicTriviaPartyId,
    questionId,
  }: Omit<ApiGamaClassicTriviaQuestionPatch, 'data'>): Observable<unknown> {
    return this._httpClient.delete<unknown>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${classicTriviaPartyId}/questions/${questionId}`,
    );
  }
}
