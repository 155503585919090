<div
  [class]="view('inner')"
  [@flipCurrencyAmount]="{
    value: amount,
  }"
  [@growCurrencyAmount]="{
    value: backValue | azaCoreCurrencyAmountWeight,
    params: {rowWidthStart: currentWidth},
  }"
  [@shrinkCurrencyAmount]="{
    value: frontValue | azaCoreCurrencyAmountWeight,
    params: {rowWidthStart: currentWidth},
  }"
  (@flipCurrencyAmount.done)="onAnimationEnd()"
>
  <div [class]="view('front')">
    <aza-core-currency-amount [amount]="frontValue"></aza-core-currency-amount>
  </div>
  <div [class]="view('back')">
    <aza-core-currency-amount [amount]="backValue"></aza-core-currency-amount>
  </div>
</div>
