import {inject, InjectionToken} from '@angular/core';
import {filter, map, Observable, shareReplay, switchMap} from 'rxjs';
// eslint-disable-next-line no-restricted-imports
import {type WebSocketSubject} from 'rxjs/webSocket';

import {ApiGameWebsocketService} from '@azarus/frontend/api/game';

import {AsyncAuthService} from '../auth/async-auth.service';

export const WEBSOCKET_SUBJECT = new InjectionToken<
  Observable<WebSocketSubject<unknown>>
>('WEBSOCKET_SUBJECT', {
  providedIn: 'root',
  factory: () => {
    const wsService = inject(ApiGameWebsocketService);
    const userService = inject(AsyncAuthService);

    return userService.isAuthorized$.pipe(
      filter(Boolean),
      switchMap(() => userService.getToken()),
      map((token) => {
        if (token === null) {
          throw new Error('User token is null unexpectedly');
        }
        return wsService.getWebsocketSubject(token, 'twitch');
      }),
      shareReplay({bufferSize: 1, refCount: true}),
    );
  },
});
