import {inject, Injectable} from '@angular/core';
// eslint-disable-next-line no-restricted-imports
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import {AccessToken} from '@azarus/api-contract';

import {API_GAME_WS_URL} from '../../../../tokens';
import {ApiGamePlatformsId} from '../../../../types';

@Injectable({providedIn: 'root'})
export class ApiGameWebsocketService {
  public readonly wsUrl = inject(API_GAME_WS_URL);

  public getWebsocketSubject<T>(
    jwtToken: AccessToken,
    platformId: ApiGamePlatformsId,
  ): WebSocketSubject<T> {
    return webSocket<T>(
      `wss://${this.wsUrl}?token=${jwtToken}&platformId=${platformId}`,
    );
  }
}
