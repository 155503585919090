import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCoreSnackbarBase} from './snackbar.base';
import {AZA_CORE_SNACKBAR_ANIMATION} from './snackbar-animation';

@Component({
  selector: 'aza-core-snackbar',
  template: '{{text}}',
  encapsulation: ViewEncapsulation.None,
  animations: [AZA_CORE_SNACKBAR_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzaCoreSnackbarComponent extends AzaCoreSnackbarBase {}
