import {Directive, HostBinding, Inject} from '@angular/core';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';

import {AZA_CORE_SNACKBAR_DATA} from './snackbar-data';

@Directive()
export abstract class AzaCoreSnackbarBase extends AzaCdkHsvsDirective {
  @HostBinding('@snackbarAnimation') public readonly animation = true;

  public constructor(
    @Inject(AZA_CORE_SNACKBAR_DATA)
    public readonly text: AzaCdkInjectionTokenType<
      typeof AZA_CORE_SNACKBAR_DATA
    >,
  ) {
    super('aza', 'snackbar');
  }
}
