import {inject, Injectable} from '@angular/core';
import {retry, switchAll, timer} from 'rxjs';

import {retentionState} from '@azarus/common/rx/retention-state';

import {WEBSOCKET_SUBJECT} from '../tokens/websocket-subject';

@Injectable({providedIn: 'root'})
export class WsService {
  private readonly _websocketSubject$ = inject(WEBSOCKET_SUBJECT);

  public readonly wsMessages$ = this._websocketSubject$.pipe(
    switchAll(),
    retry({delay: (_e) => timer(1500)}),
    retentionState(3000),
  );
}
