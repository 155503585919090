import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AccountId} from '@azarus/api-contract';

import {API_GAME_HTTP_URL} from '../../../../tokens';
import {ApiGameEnrollmentResponse} from '../type';

@Injectable({providedIn: 'root'})
export class ApiGameEnrollmentService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public nextEnrollment(
    chanelId: AccountId,
  ): Observable<ApiGameEnrollmentResponse> {
    return this._httpClient.get<ApiGameEnrollmentResponse>(
      `${this._httpUrl}/platforms/twitch/streamers/${chanelId}/sessions/next`,
    );
  }
}
