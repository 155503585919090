import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Router} from '@angular/router';
import {
  catchError,
  defer,
  distinctUntilChanged,
  EMPTY,
  first,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import {AccessToken, RefreshToken, TokenCreds} from '@azarus/api-contract';
import {asClass} from '@azarus/common/transformer/class/as-class';
import {field} from '@azarus/common/transformer/class/field';
import {asNullable} from '@azarus/common/transformer/nullable/as-nullable';
import {asString} from '@azarus/common/transformer/string/as-string';
import {IsoDateString} from '@azarus/common/type/iso-date-string';
import {
  ApiGatewayAuthService,
  ApiGatewayTokenRefreshService,
  ApiGatewayUserService,
} from '@azarus/frontend/api/gateway';
import {AzaCdkLocalStorageObservable} from '@azarus/frontend/cdk/local-storage-observable/local-storage-observable';

import {environment} from '../../environments/environment';
import {AppRouteSegment} from '../app-route-segment.enum';

import {AsyncAuthService} from './async-auth.service';

class TokenCredsDto implements TokenCreds {
  @field(asNullable(asString<AccessToken>()))
  public readonly accessToken: AccessToken;
  @field(asNullable(asString<RefreshToken>()))
  public readonly refreshToken: RefreshToken;
  @field(asNullable(asString<IsoDateString>()))
  public readonly accessTokenExpiresAt: IsoDateString;
  @field(asNullable(asString<IsoDateString>()))
  public readonly refreshTokenExpiresAt: IsoDateString;

  public constructor(
    accessToken: TokenCredsDto['accessToken'],
    refreshToken: TokenCredsDto['refreshToken'],
    accessTokenExpiresAt: TokenCredsDto['accessTokenExpiresAt'],
    refreshTokenExpiresAt: TokenCredsDto['refreshTokenExpiresAt'],
  ) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.accessTokenExpiresAt = accessTokenExpiresAt;
    this.refreshTokenExpiresAt = refreshTokenExpiresAt;
  }
}

export class LocalAsyncAuthService implements AsyncAuthService {
  private readonly _tokenKey = `${environment.storageKeyPrefix}tokenCredsV1`;
  private readonly _lsc$ = new AzaCdkLocalStorageObservable(
    this._tokenKey,
    () => null,
    asNullable(asClass(TokenCredsDto)),
  );
  public isAuthorized$: Observable<boolean> = this._lsc$.pipe(
    map((creds) => creds !== null),
    distinctUntilChanged(),
  );

  public constructor(
    private readonly _authApi: ApiGatewayAuthService,
    private readonly _tokenRefresher: ApiGatewayTokenRefreshService,
    private readonly _apiGatewayUserService: ApiGatewayUserService,
    private readonly _router: Router,
  ) {}

  public getToken(): Observable<AccessToken | null> {
    return defer(() => {
      const credentials = this._lsc$.getValue();
      if (credentials === null) {
        return of(null);
      }
      return this._tokenRefresher.getFreshToken(
        credentials,
        () => {
          this._lsc$.save(null);
          void this._router.navigate([
            AppRouteSegment.ROOT,
            AppRouteSegment.LOGIN,
          ]);
          return EMPTY;
        },
        () =>
          this._authApi
            .refreshCreds(credentials.refreshToken, credentials.accessToken)
            .pipe(
              tap((creds) => this.setCreds(creds)),
              map(({accessToken}) => accessToken),
            ),
      );
    });
  }

  public setCreds(creds: TokenCreds): void {
    this._lsc$.save(
      new TokenCredsDto(
        creds.accessToken,
        creds.refreshToken,
        creds.accessTokenExpiresAt,
        creds.refreshTokenExpiresAt,
      ),
    );
  }

  public logout(): Observable<void> {
    return this._lsc$.pipe(
      first(),
      switchMap((creds) => {
        if (creds === null) {
          throw new Error('Called logout when not logged in');
        }
        return this._authApi.logout(creds.accessToken).pipe(
          map(() => undefined),
          catchError((error) => {
            if (
              error instanceof HttpErrorResponse &&
              error.status === HttpStatusCode.Unauthorized
            ) {
              return of(undefined);
            }
            return throwError(() => error);
          }),
          tap(() => this._lsc$.save(null)),
        );
      }),
    );
  }

  public deleteUser(): Observable<void> {
    return this._apiGatewayUserService.deleteUser().pipe(
      tap(() => {
        this._lsc$.save(null);
        void this._router.navigate([
          AppRouteSegment.ROOT,
          AppRouteSegment.LOGIN,
        ]);
      }),
    );
  }
}
