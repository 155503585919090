import {ComponentType} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';

import {AzaCoreSnackbarBaseService} from './snackbar.base-service';
import {AzaCoreSnackbarComponent} from './snackbar.component';

@Injectable({providedIn: 'root'})
export class AzaCoreSnackbarService extends AzaCoreSnackbarBaseService {
  protected override get snackbarComponent(): ComponentType<AzaCoreSnackbarComponent> {
    return AzaCoreSnackbarComponent;
  }
}
