import {APP_INITIALIZER, NgModule} from '@angular/core';

import {twitchConnectResultCatchInitializer} from './initializers/twitch-connect-result-catch.initializer';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return twitchConnectResultCatchInitializer();
      },
      multi: true,
    },
  ],
})
export class AzarusAccountCollectModule {}
