import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {UserId} from '@azarus/api-contract';

import {API_GAME_HTTP_URL} from '../../tokens';
import {UserBalanceResponse} from '../types/user-balance-response';

@Injectable({providedIn: 'root'})
export class ApiGameUserBalanceService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getBalance(userId: UserId): Observable<UserBalanceResponse> {
    return this._httpClient.get<UserBalanceResponse>(
      `${this._httpUrl}/users/${userId}/balances`,
    );
  }
}
