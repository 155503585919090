import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {Inject, Injectable, Injector, OnDestroy} from '@angular/core';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';

import {AZA_CORE_SNACKBAR_DATA} from './snackbar-data';
import {AzaCoreSnackbarOptions} from './snackbar-options';
import {AzaCoreSnackbarRef} from './snackbar-ref';
import {AZA_CORE_SNACKBAR_TIME_CLOSE} from './snackbar-time-close';

const DEFAULT_SNACKBAR_OPTIONS: Omit<
  Required<AzaCoreSnackbarOptions>,
  'timeToCloseMs'
> = {
  width: '340px',
  bottom: '50px',
};

@Injectable()
export abstract class AzaCoreSnackbarBaseService implements OnDestroy {
  private _timeout: ReturnType<typeof setTimeout> | null = null;

  private _overlayRef: OverlayRef | null = null;
  private _snackbarRef: AzaCoreSnackbarRef<any> | null = null;

  protected constructor(
    private readonly _overlay: Overlay,
    @Inject(AZA_CORE_SNACKBAR_TIME_CLOSE)
    public readonly _timeClose: AzaCdkInjectionTokenType<
      typeof AZA_CORE_SNACKBAR_TIME_CLOSE
    >,
  ) {}

  protected abstract get snackbarComponent(): ComponentType<unknown>;

  public open<D>(
    text: string,
    options?: AzaCoreSnackbarOptions,
  ): AzaCoreSnackbarRef<D> {
    this._snackbarRef?.close();

    this._snackbarRef = new AzaCoreSnackbarRef<D>(() => {
      this._clearTimeout();
      this._dispose();
      this._snackbarRef = null;
    });
    this._overlayRef = this._overlay.create(this._createConfig(options));

    const filePreviewPortal = new ComponentPortal(
      this.snackbarComponent,
      null,
      this._createInjector<D>(text, this._snackbarRef),
    );

    this._overlayRef.attach(filePreviewPortal);

    // timeToClose has 2 possible sources - injected AZA_CORE_SNACKBAR_TIME_CLOSE
    // and options parameter
    // optons paramater is prior than injected default
    // Following values are possible:
    //   * numeric value - time in ms to close the snackbar
    //   * null - explicit order to not use timer to close
    //   * undefined - using less prior source
    let timeToClose = options?.timeToCloseMs;
    if (timeToClose === undefined) {
      timeToClose = this._timeClose;
    }

    if (typeof timeToClose === 'number') {
      this._timeout = setTimeout(() => {
        this._snackbarRef?.close();
      }, timeToClose);
    }
    return this._snackbarRef;
  }

  private _createConfig(
    options: AzaCoreSnackbarOptions | undefined,
  ): OverlayConfig {
    const fullOptions = {...DEFAULT_SNACKBAR_OPTIONS, ...options};
    return new OverlayConfig({
      width: fullOptions.width,
      positionStrategy: this._overlay
        .position()
        .global()
        .bottom(fullOptions.bottom)
        .centerHorizontally(),
    });
  }

  private _dispose(): void {
    if (this._overlayRef !== null) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }

  private _clearTimeout(): void {
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }
  }

  private _createInjector<D>(
    text: AzaCdkInjectionTokenType<typeof AZA_CORE_SNACKBAR_DATA>,
    snackbarRef: AzaCoreSnackbarRef<D>,
  ): Injector {
    return Injector.create({
      providers: [
        {provide: AZA_CORE_SNACKBAR_DATA, useValue: text},
        {provide: AzaCoreSnackbarRef, useValue: snackbarRef},
      ],
    });
  }

  public ngOnDestroy(): void {
    this._snackbarRef?.close();
  }
}
