import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  ViewEncapsulation,
} from '@angular/core';

import {AzaCdkInjectionTokenType} from '@azarus/frontend/cdk/alias/injection-token-type';
import {AzaCdkHsvsDirective} from '@azarus/frontend/cdk/hsvs/hsvs.directive';
import {AzaCoreButtonModule} from '@azarus/frontend/core/modules/button/button.module';
import {AZA_CORE_SNACKBAR_ANIMATION} from '@azarus/frontend/core/modules/snackbar/snackbar-animation';
import {AZA_CORE_SNACKBAR_DATA} from '@azarus/frontend/core/modules/snackbar/snackbar-data';
import {AzaCoreSnackbarRef} from '@azarus/frontend/core/modules/snackbar/snackbar-ref';

@Component({
  selector: 'aza-core-closable-error-snackbar',
  template: `
    {{ text }}
    <button
      aza-core-button
      [class]="view('button')"
      color="outlined"
      i18n
      size="small"
      type="button"
      (click)="snackbarRef.close()"
    >
      Ok
    </button>
  `,
  encapsulation: ViewEncapsulation.None,
  animations: [AZA_CORE_SNACKBAR_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AzaCoreButtonModule],
})
export class AzaCoreClosableErrorSnackbarComponent extends AzaCdkHsvsDirective {
  @HostBinding('@snackbarAnimation') public readonly animation = true;

  public constructor(
    @Inject(AZA_CORE_SNACKBAR_DATA)
    public readonly text: AzaCdkInjectionTokenType<
      typeof AZA_CORE_SNACKBAR_DATA
    >,
    protected readonly snackbarRef: AzaCoreSnackbarRef<void>,
  ) {
    super('aza', 'closableErrorSnackbar');
  }
}
