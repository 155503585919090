import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {API_GAME_HTTP_URL} from '../../../../tokens/http-url';
import {ApiGameGameBaseRequest} from '../../../../types';
import {ApiGameClassicTriviaParty} from '../types/party';
import {ApiGameClassicTriviaPartyCreateRequest} from '../types/party-create-request';
import {ApiGameClassicTriviaPartyList} from '../types/party-list';
import {ApiGameClassicTriviaPartyPatch} from '../types/party-patch';

@Injectable({providedIn: 'root'})
export class ApiGameClassicTriviaPartiesService {
  private readonly _httpUrl = inject(API_GAME_HTTP_URL);
  private readonly _httpClient = inject(HttpClient);

  public getListParties({
    gameId,
    userId,
    gameVariationId,
  }: ApiGameGameBaseRequest): Observable<ApiGameClassicTriviaPartyList> {
    return this._httpClient.get<ApiGameClassicTriviaPartyList>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties`,
    );
  }

  public createParty({
    userId,
    gameId,
    gameVariationId,
    data,
  }: ApiGameClassicTriviaPartyCreateRequest): Observable<ApiGameClassicTriviaParty> {
    return this._httpClient.post<ApiGameClassicTriviaParty>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties`,
      data,
    );
  }

  public patchParty({
    userId,
    gameId,
    gameVariationId,
    classicTriviaPartyId,
    data,
  }: ApiGameClassicTriviaPartyPatch): Observable<unknown> {
    return this._httpClient.patch<unknown>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${classicTriviaPartyId}`,
      data,
    );
  }

  public deleteParty({
    userId,
    gameVariationId,
    gameId,
    classicTriviaPartyId,
  }: Omit<ApiGameClassicTriviaPartyPatch, 'data'>): Observable<unknown> {
    return this._httpClient.delete<unknown>(
      `${this._httpUrl}/users/${userId}/games/${gameId}/${gameVariationId}/parties/${classicTriviaPartyId}`,
    );
  }
}
